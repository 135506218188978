// Place any jQuery/helper plugins in here.

// Focus first element in Calc form...
function FocusCompanyElement() {
  $('#calc-company').focus();
}

function GetCalcResults() {
  $.ajax({
    url:
        "calculator.results.php?CompanyID=" + encodeURIComponent($('#calc-companyid').val()) + "&CompanyName=" + encodeURIComponent($('#calc-company').val()) +
        "&Profit1=" + encodeURIComponent($('#calc-company-profit').val()) + "&Revenue1=" + encodeURIComponent($('#calc-company-revenue').val()) + "&Employees1=" + encodeURIComponent($('#calc-company-employees').val()) + "&Year1=" + encodeURIComponent($('#calc-company-year').val()) +
        "&Profit2=" + encodeURIComponent($('#calc-profit').val()) + "&Revenue2=" + encodeURIComponent($('#calc-revenue').val()) + "&Employees2=" + encodeURIComponent($('#calc-employees').val()) +
        "&POs=" + encodeURIComponent($('#calc-objednavky-value').val()) + "&Invoices=" + encodeURIComponent($('#calc-faktury-value').val()) + "&Manually=" +  encodeURIComponent($('#calc-rucne').val()) + "&Approval=" + encodeURIComponent($("input[name='calc-schvalovanie']").groupVal()),
    success: function(result){
      //console.log('Great! We have results here!');
      //console.log(result);
      var resultObj = JSON.parse(result);

      $('#calc-result-0-companyname').html(resultObj.CompanyName).show();

      $('#calc-result-1-msg').html(resultObj.SavedTimeMsg).show();
      $('#calc-result-1').data('text', resultObj.SavedTime + "%");
      CircleCalcResult1.update(100 - resultObj.SavedTime);

      $('#calc-result-2').html(resultObj.SavedCosts);
      $('#calc-result-2-msg').html(resultObj.SavedCostsMsg).show();

      $('#calc-result-3').html(resultObj.DecreaseIssues);
      $('#calc-result-3-inmsg').html(Math.abs(resultObj.DecreaseIssues));

      $('#calc-result-4-msg').html(resultObj.SpeedUpMsg).show();
      $('#calc-result-4').data('text', resultObj.SpeedUpWithUnit);
      CircleCalcResult4.update(100 - resultObj.SpeedUpPerc);

      // pripravime si aj GEO informacie, ak by siel odosielat formular:
      $.get("https://ipinfo.io", function (response) {
        $("input[type=hidden].geo-ipinfo").val(JSON.stringify(response));
      }, "jsonp");

    }});

  // tracking
  TrackAction();
}

// Find value of radio-buttons easily
jQuery.fn.extend({
  groupVal: function() {
    return $(this).filter(':checked').val();
  }
});

var CircleCalcResult1 = null;
var CircleCalcResult4 = null;

$(function() {
  /**
   * enable tooltips everywhere
   */
  $('[data-toggle="tooltip"]').tooltip();

  var $calculator = $('#calculator');
  var $results = $('#results');
  var $revenue = $('#revenue');

  if ($calculator.length && $revenue.length && $results.length) {

    $('.js-calculatorSubmit').click(function() {
      var $this = $(this);

      if (
          ($("input[name='calc-schvalovanie']").groupVal() === undefined) ||
          ($('#calc-company').val().length === 0)
      ) {
        alert('Musíte vyplniť všetky údaje v tomto formulári.');
        FocusCompanyElement();
      } else {
        if ( ($('#calc-company-profit').val().length > 0) && ($('#calc-company-revenue').val().length > 0)  && ($('#calc-company-employees').val().length > 0) ) {
          // mame nacitany zisk, obrat a pocet zamestnancov, zavrieme calculator a otvorime vysledky:
          // 1) vypocet vysledkov:
          //console.log('All fine... Going to step 3...');
          GetCalcResults();
          // 2) zobrazenie vysledkov:
          $calculator.one('hidden.bs.modal', showResults);
          $calculator.modal('hide');
        } else if ((parseInt($('#calc-revenue').val()) > 0) && (parseInt($('#calc-profit').val()) > 0) && (parseInt($('#calc-employees').val()) > 0) && ($this.attr('id') === 'calc-button2')) {
            // mame vyplneny obrat/pocet zamestnacov a ideme z druheho kroku, zavrieme revenue alebo calculator a ideme na vysledky:
            // 1) vypocet vysledkov:
            //console.log('All fine thanks to user... Going to step 3...');
            GetCalcResults();
            // 2) zobrazenie vysledkov:
            $revenue.one('hidden.bs.modal', showResults);
            $revenue.modal('hide');
        } else {
          // nemame komplet udaje o obrate/zisku/pocte zamestnancov, ideme ziskat chybajuce udaje:

          // mame aspon zisk?
          if ($('#calc-company-profit').val().length > 0) {
            $('#calc-block-profit').hide();
            $('#calc-profit').val($('#calc-company-profit').val());
          } else {
            $('#calc-block-profit').show();
          }

          // mame aspon obrat?
          if ($('#calc-company-revenue').val().length > 0) {
            $('#calc-block-revenue').hide();
            $('#calc-revenue').val($('#calc-company-revenue').val());
          } else {
            $('#calc-block-revenue').show();
          }

          // mame aspon pocet zamestnancov - ten neskryvame, ani nepredvyplnime...
          if ($('#calc-company-employees').val().length > 0) {
              $('#calc-block-employees').show();
          } else {
            $('#calc-block-employees').show();
          }

          $calculator.one('hidden.bs.modal', showRevenue);
          $calculator.modal('hide');
        }
      }

      //$('body').addClass('modal-open');
    });

    function showRevenue() {
      $revenue.modal('show');
      $calculator.off('hidden.bs.modal', showRevenue);
    }

    function showResults() {
      $results.modal('show');
      $calculator.off('hidden.bs.modal', showResults);
    }

    var fieldCompany = $('#calc-company');
    fieldCompany.autoComplete({
      resolverSettings: {
        url: 'calculator.lookup.php?',
        requestThrottling: 500
      }
    });

    fieldCompany.on('autocomplete.select', function (evt, item) {
      $('#calc-button1').attr("disabled", true);

      //console.log('AutoComplete selected here...');
      // Dotiahneme Zisk, Obrat a Pocet zamestnancov:
      $.ajax({url: "calculator.details.php?CompanyID=" + encodeURIComponent(item.id) + "&CompanyName=" + encodeURIComponent($('#calc-company').val()),
            success: function(result){
              $('#calc-companyid').val(result.ICO);

              $('#calc-company-profit').val(result.Profit);
              $('#calc-company-revenue').val(result.Revenue);
              $('#calc-company-employees').val(JSON.stringify(result.Employees));
              $('#calc-company-year').val(result.Year);

              //console.log('   Values loaded from AJAX...');

              var CalcButton1 = $('#calc-button1');
              if ((result.Profit != null) && (result.Revenue != null) && (result.Employees != null)) {
                CalcButton1.html('Kalkulovať');
                //console.log('   All ok...');
              } else {
                CalcButton1.html('Ďalej &gt;');
                //console.log('   Something missing...');
              }
              CalcButton1.attr("disabled", false);
            }});
    });

    fieldCompany.on('autocomplete.freevalue', function (evt, value) {
      $('#calc-button1').attr("disabled", true);
      $('#calc-companyid').val('');

      //console.log('Freeval/OnChange here...');
      // Dotiahneme Zisk, Obrat a Pocet zamestnancov:
      $.ajax({url: "calculator.details.php?CompanyName=" + encodeURIComponent(value),
        success: function(result){
          //console.log(result);
          $('#calc-companyid').val(result.ICO);
          //$('#calc-company').val(result.Name);
          $('#calc-company').autoComplete('set', result.Name); //{ value: result.ICO, text: result.Name });

          $('#calc-company-profit').val(result.Profit);
          $('#calc-company-revenue').val(result.Revenue);
          $('#calc-company-employees').val(result.Employees);
          $('#calc-company-year').val(result.Year);

          //console.log('   Values loaded from AJAX...');

          var CalcButton1 = $('#calc-button1');
          if ((result.Profit != null) && (result.Revenue != null) && (result.Employees != null)) {
            CalcButton1.html('Kalkulovať');
            //console.log('   All ok...');
          } else {
            CalcButton1.html('Ďalej &gt;');
            //console.log('   Something missing...');
          }
          CalcButton1.attr("disabled", false);
        }});

    });

    // ------------ odoslat kontakt --------------
    var msgSubmitFunc = function (event) {
      var form = $(this);
      // validacia:
      var err = "";

      /*
      form.find("#demo-year").each(function () {
        if ($(this).val() !== {$JSYearStr2}) {
          err = 'Musíte vyplniť správny rok.'
        }
      });
      */
      form.find(".required").each(function () {
        if ($(this).val().length <= 1) {
          err = 'Musíte vyplniť povinné položky.'
        }
      });

      if (err.length > 0) {
        alert(err);
      } else {

        // odoslanie:
        $.ajax({
          type: form.attr('method'),
          url: form.attr('action'),
          data: $('#calc-step1, #calc-step2, #' + form.attr('id')).serialize()
        }).done(function () {
          // Success here...
          $calculator.modal('hide');
          $revenue.modal('hide');
          $results.modal('hide');

          alert('Ďakujeme, budeme Vás kontaktovať.');

          // tracking
          TrackAction();
        }).fail(function () {
          // An error here...
          alert('Odoslanie správy zlyhalo, prosím skontrolujte odosielané údaje.');
        });
      }
      event.preventDefault(); // Prevent the form from submitting via the browser.
    };

    // Priradenie f-cie k eventu:
    $('#calc-results-form').submit(msgSubmitFunc);


  } // mame kalkulacku na stranke?

  /**
   * Circles
   * https://github.com/lugolabs/circles
   */

  (function() {
    var $circle1 = $('#calc-result-1');
    if ($circle1.length) {
      var value = $circle1.data('value');

      CircleCalcResult1 = Circles.create({
        id: 'calc-result-1',
        radius: 60,
        value: parseFloat(value),
        maxValue: 100,
        width: 10,
        text: function() { return $circle1.data('text'); },
        colors: ['#D3B6C6', '#4B253A'],
        duration: 400,
        wrpClass: 'Circle-wrap',
        textClass: 'Circle-text',
        valueStrokeClass: 'Circle-valueStroke',
        maxValueStrokeClass: 'Circle-maxValueStroke',
        styleWrapper: false,
        styleText: false
      });
    }
  })();

  (function() {
    var $circle2 = $('#calc-result-4');
    if ($circle2.length) {
      var value = $circle2.data('value');

      CircleCalcResult4 = Circles.create({
        id: 'calc-result-4',
        radius: 60,
        value: parseFloat(value),
        maxValue: 100,
        width: 10,
        text: function() { return $circle2.data('text'); },
        colors: ['#D3B6C6', '#4B253A'],
        duration: 400,
        wrpClass: 'Circle-wrap',
        textClass: 'Circle-text',
        valueStrokeClass: 'Circle-valueStroke',
        maxValueStrokeClass: 'Circle-maxValueStroke',
        styleWrapper: false,
        styleText: false
      });
    }
  })();


  /**
   * Carousels
   */

  var prevArrow = '<button class="Carousel-prev slick-prev" type="button"><span class="Arrow Arrow--left" aria-hidden="true"><span class="Arrow-bar"></span></span ><span class="sr-only">Previous</span></button>';
  var nextArrow = '<button class="Carousel-next slick-next" type="button"><span class="Arrow" aria-hidden="true"><span class="Arrow-bar"></span></span ><span class="sr-only">Next</span></button>';

  var $clientsCarousel = $('.js-clientsCarousel');
  if ($clientsCarousel.length) {

    $clientsCarousel.slick({
      infinite: true,
      prevArrow: prevArrow,
      nextArrow: nextArrow,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }

  var $testimonialsCarousel = $('.js-testimonialsCarousel');
  if ($testimonialsCarousel.length) {

    $testimonialsCarousel.slick({
      infinite: true,
      prevArrow: prevArrow,
      nextArrow: nextArrow,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  }


  /**
   * CountUp
   */
  function easingFn(t, b, c, d) {
    var ts = (t /= d) * t;
    var tc = ts * t;
    return b + c * (tc + -3 * ts + 3 * t);
  }

  var $countUpEls = $('.js-countUp');
  var countUpOptions = {
    easingFn: easingFn,
    separator: ' ',
  }
  var countUpStarted = false;

  function checkCountUp() {
    if (isElementInViewport($countUpEls)) {
      countUpStarted = true;

      $(window).off('scroll', checkCountUp);

      for (var i = 0; i < $countUpEls.length; i++) {
        var el = $countUpEls[i];
        var targetValue = parseInt($(el).data('target'), 10);
        // console.log(targetValue);

        var countUp = new CountUp(el, 0, targetValue, 0, 2, countUpOptions);
        if (!countUp.error) {
          countUp.start();
        } else {
          console.error(countUp.error);
        }
      }
    }
  }

  if ($countUpEls.length) {
    $(window).on('scroll', checkCountUp);
  }
});

